import { components } from 'react-select';
import { faFolder } from '@fortawesome/pro-regular-svg-icons';
import { useEffect, useState } from 'react';

import { useContactContext } from 'lib/common/contexts/ContactContext';
import IWrapUpCode from 'lib/common/types/WrapUpCode';
import Icon from 'lib/common/components/Icon';
import ReactSelect from 'lib/common/components/ReactSelect';
import Text from 'lib/common/components/Text';

import Task from 'lib/common/types/Task';
import ChatTask from 'lib/common/types/ChatTask';
import TaskContentTypes from 'lib/common/types/TaskContentTypes';

import './acw-code-selector.scss';

interface IACWCodeLabel {
  wrapUpCode: IWrapUpCode;
}

function SingleValue(props) {
  const { children, ...rest } = props;
  const { selectProps } = props;
  if (selectProps.menuIsOpen) return <></>;
  return <components.SingleValue {...rest}>{children}</components.SingleValue>;
}

function ACWCodeLabel({ wrapUpCode: { name, pathname } }: IACWCodeLabel) {
  return (
    <div className="acw-outcome-selector__label">
      <div className="acw-outcome-selector__path">
        <Icon color="darkGrey" size={12} icon={faFolder} />
        <Text color="darkGrey" className="acw-outcome-selector__path__text" type="extraSmall" ellipsis>
          {pathname || '/'}
        </Text>
      </div>
      <Text className="acw-outcome-selector__label__name" medium ellipsis>
        {name}
      </Text>
    </div>
  );
}

interface IACWCodeSelector {
  selectedTask: Task | ChatTask;
  queueWrapUpCodes: IWrapUpCode[];
  loaded: boolean;
}

export default function ACWCodeSelector({
  selectedTask: { ACW, queueId, taskId },
  queueWrapUpCodes,
  loaded
}: IACWCodeSelector) {
  const [code, setCode] = useState<IWrapUpCode | undefined>(ACW?.wrapUpCodeName);

  const {
    actions: { setTaskContent }
  } = useContactContext();

  useEffect(() => {
    setCode(ACW?.wrapUpCodeName);
  }, [taskId]);

  if (queueId === undefined) {
    return <Text>No Queue Set</Text>;
  }

  const noResults = () => {
    if (!queueWrapUpCodes.length) {
      return 'No wrap up codes have been set up for this queue yet';
    }
    return 'No wrap up codes match your search';
  };

  const options = queueWrapUpCodes.map((wrapUpCode: IWrapUpCode) => {
    return { value: wrapUpCode.name, label: <ACWCodeLabel wrapUpCode={wrapUpCode} />, wrapUpCode };
  });

  const filterOption = (candidate, input) => {
    if (!input) {
      return true;
    }

    const { name, pathname } = candidate?.label?.props?.wrapUpCode || { name: '', pathname: '' };

    return (
      name.toLowerCase().includes(input.toLowerCase()) || pathname.toLowerCase().includes(input.toLowerCase()) || false
    );
  };

  const onChange = (option, actionMeta) => {
    setCode(actionMeta?.action === 'clear' ? void 0 : option.wrapUpCode);

    setTaskContent(taskId, TaskContentTypes.ACW, {
      ...ACW,
      wrapUpCodeName: actionMeta?.action === 'clear' ? void 0 : option.wrapUpCode
    });
  };

  const selectedCode = options.find((option) => option.value === code?.name) || null;
  const disabled = loaded && !options.length;
  const placeholder = disabled ? 'No codes are set for this queue' : 'Search & select a wrap code';

  return (
    <ReactSelect
      autoFocus
      aria-label="Outcomes"
      isSearchable
      name="outcomes"
      components={{ SingleValue }}
      options={options}
      classNamePrefix="select-acw"
      onChange={onChange}
      isDisabled={disabled}
      isClearable
      placeholder={placeholder}
      value={selectedCode}
      isLoading={!loaded}
      noOptionsMessage={noResults}
      filterOption={filterOption}
      id="select-acw"
    />
  );
}
