import { useState, useEffect, useMemo } from 'react';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import { $insertNodes, EditorState } from 'lexical';
import _debounce from 'lodash.debounce';

const HTML_CONVERT_DEBOUNCE_MS = 500;

interface Props {
  initialHtml?: string;
  onHtmlChanged: (html: string) => void;
}

const HtmlPlugin = ({ initialHtml, onHtmlChanged }: Props) => {
  const [editor] = useLexicalComposerContext();

  const [isFirstRender, setIsFirstRender] = useState(true);

  const onChange = useMemo(
    () =>
      _debounce((editorState: EditorState) => {
        editorState.read(() => {
          onHtmlChanged($generateHtmlFromNodes(editor));
        });
      }, HTML_CONVERT_DEBOUNCE_MS),
    [onHtmlChanged]
  );

  useEffect(() => {
    if (!initialHtml || !isFirstRender) return;

    setIsFirstRender(false);

    editor.update(() => {
      const parser = new DOMParser();
      const dom = parser.parseFromString(initialHtml, 'text/html');
      const nodes = $generateNodesFromDOM(editor, dom);
      $insertNodes(nodes);
    });
  }, []);

  return <OnChangePlugin onChange={onChange} />;
};

export default HtmlPlugin;
