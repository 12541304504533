import QUEUE_ENDPOINT_TYPES from 'lib/common/constants/queueEndpointTypes';

import Text from 'lib/common/components/Text';
import Label from 'lib/common/components/Label';
import ReactSelect from 'lib/common/components/ReactSelect';

import './transfer-to-queue.scss';

interface ITransferQueue {
  connectionValue: string;
  endpoints: any;
  selectedEndpoint: any;
  setSelectedEndpoint: any;
}

interface Queue {
  Id: string;
  Arn: string;
  Name: string;
  QueueType: ValueOf<typeof QUEUE_ENDPOINT_TYPES>;
}

const TransferToQueue = ({ connectionValue, endpoints, selectedEndpoint, setSelectedEndpoint }: ITransferQueue) => (
  <>
    <Text type="body" className="transfer-to-queue__agent-transfer-info">
      <span>We'll transfer</span>
      <strong>{connectionValue}</strong>
      <span>to the new queue for another agent to accept</span>
    </Text>
    <Label id="transfer-queue" text="Queue">
      <ReactSelect
        id="transfer-queue-select"
        name="transfer-queue"
        isSearchable
        autoFocus
        placeholder="Search and select a queue..."
        options={endpoints}
        className="select__control__presence-states"
        classNamePrefix="select"
        onChange={(selected) => setSelectedEndpoint(selected as { value: Queue; label: string })}
        value={selectedEndpoint}
        aria-label="transfer-queue-select"
      />
    </Label>
  </>
);

export default TransferToQueue;
