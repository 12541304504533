import { KeyboardEvent, useEffect, useState } from 'react';
import { LexicalEditor } from 'lexical';
import { TOGGLE_LINK_COMMAND } from '@lexical/link';

import Modal from 'lib/common/components/Modal';
import { Input } from 'lib/common/components/Input';

import addProtocolToUrl from './utils/addProtocolToUrl';

export default function EditLinkModal({
  initialLink,
  open,
  onClose,
  editor
}: {
  initialLink: string | null;
  open: boolean;
  onClose: (link: string | null) => void;
  editor: LexicalEditor;
}) {
  // Use state instead of useRef because we need to focus the input after the ref is set (not modal open which takes focus) and requires a re-render
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);

  const [link, setLink] = useState(initialLink);

  const onInputKeyDown = (event: KeyboardEvent) => {
    if (event.key !== 'Enter') {
      return;
    }

    event.preventDefault();

    editor.dispatchCommand(TOGGLE_LINK_COMMAND, addProtocolToUrl(link) || null);

    onClose(addProtocolToUrl(link) || null);
  };

  const onSave = () => {
    editor.dispatchCommand(TOGGLE_LINK_COMMAND, addProtocolToUrl(link) || null);

    onClose(addProtocolToUrl(link) || null);
  };

  useEffect(() => {
    setLink(initialLink);
  }, [open]);

  useEffect(() => {
    inputRef?.focus();
  }, [inputRef]);

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose(initialLink);

        if (link) {
          return;
        }

        editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
      }}
      onSave={onSave}
      title={initialLink ? 'Edit Link' : 'Add a Link'}
    >
      <Input
        value={link}
        onChange={(event) => {
          setLink(event.target.value);
        }}
        inputProps={{
          ref: setInputRef
        }}
        onKeyDown={onInputKeyDown}
      />
    </Modal>
  );
}
