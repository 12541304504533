import CONTACT_STATES from 'lib/common/constants/contactStates';
import CONTACT_TYPES from 'lib/common/constants/contactTypes';
import CONNECTION_STATES from 'lib/common/constants/connectionStates';
import CONNECTION_TYPE_MAP from 'lib/common/constants/connectionType';
import connectGetter from 'lib/common/utils/connectGetter';
import CONNECT_REJECTED_STATUS from 'lib/common/constants/connectRejectedState';
import getContactConnection from 'lib/common/utils/getContactConnection';
import getQueueIdFromArn from 'lib/common/utils/queues/getQueueIdFromArn';
import TTask from 'lib/common/types/Task';

import getTaskType from '../getTaskType';
import getTaskContent from '../getTaskContent';

const CONNECT_CONTACT_STATE_MAP = {
  [connect.ContactStateType.INIT]: CONTACT_STATES.CONNECTING,
  [connect.ContactStateType.INCOMING]: CONTACT_STATES.CONNECTING,
  [connect.ContactStateType.PENDING]: CONTACT_STATES.CONNECTING,
  [connect.ContactStateType.CONNECTING]: CONTACT_STATES.CONNECTING,
  [connect.ContactStateType.CONNECTED]: CONTACT_STATES.CONNECTED,
  [connect.ContactStateType.MISSED]: CONTACT_STATES.MISSED,
  [connect.ContactStateType.ERROR]: CONTACT_STATES.MISSED,
  [connect.ContactStateType.ENDED]: CONTACT_STATES.ACW,
  [CONNECT_REJECTED_STATUS]: CONTACT_STATES.REJECTED
};

const CONNECT_CONNECTION_STATE_MAP = {
  [connect.ConnectionStateType.HOLD]: CONNECTION_STATES.HOLD
};

const CUSTOMER_CONNECTION_VALUE = 'Customer';

//This should only be used for new contacts/tasks that have not already been initialized i.e are inbound or after refresh or state change of toplevel provider
export default function newBaseTask(contact: connect.Contact, status?: ValueOf<typeof CONTACT_STATES>): TTask | null {
  const connection = getContactConnection(contact);
  const connectionType = connectGetter(contact, 'isInbound')
    ? CONNECTION_TYPE_MAP.INBOUND
    : CONNECTION_TYPE_MAP.OUTBOUND;

  const contactState = connectGetter(contact, 'getState');
  const connectionState = connectGetter(connection, 'getState');

  const queue = connectGetter(contact, 'getQueue');
  const type = getTaskType(contact);

  // Set to undefined as calling here is not when the agent actually connects but when the agent receives an incoming call
  // Doing this explicitly, so there is somewhere to explain this behaviour
  const connectedAtTime = undefined;

  if (
    !type ||
    !contactState ||
    (type !== CONTACT_TYPES.TASK &&
      type !== CONTACT_TYPES.EMAIL &&
      type !== CONTACT_TYPES.OUTBOUND_PREVIEW &&
      !connectionState)
  ) {
    return null;
  }

  return {
    queueName: queue?.name,
    queueId: getQueueIdFromArn(queue?.queueARN),
    ACW: {},
    contact,
    status: status || CONNECT_CONTACT_STATE_MAP[contactState?.type],
    type,
    connectionValue:
      connectGetter(connection, 'getEndpoint')?.phoneNumber ||
      connectGetter(contact, 'getName') ||
      CUSTOMER_CONNECTION_VALUE,
    connectionState: (connectionState && CONNECT_CONNECTION_STATE_MAP[connectionState?.type]) || CONNECTION_STATES.LIVE,
    connectionType,
    taskId: contact.contactId,
    // Set our own time here, because the connect timestamp is unreliable AF (sometimes get negative times/no reset)
    time: ((connection && connectionState?.timestamp) || contactState?.timestamp) ?? new Date(),
    description: connectGetter(contact, 'getDescription'),
    connectedAtTime,
    connectionTimestamps: {},
    contactSnapshot: contact.toSnapshot(),
    ...getTaskContent(type, contact)
  };
}
