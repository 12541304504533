import { faPlay, faStop } from '@fortawesome/pro-regular-svg-icons';
import { useEffect, useState } from 'react';
import PhoneNumber from 'awesome-phonenumber';
import { useBooleanState } from 'webrix/hooks';

import 'react-phone-input-2/lib/style.css';

import { useLayout } from 'lib/common/contexts/layout/LayoutContext';
import { useLocalStorage } from 'lib/common/hooks/useLocalStorage';
import useTrigger from 'lib/common/hooks/useTrigger';
import AUSTRALIA_PHONE_EXTENSION from 'lib/common/constants/defaultPhoneNumberExtension';
import WORKSPACE_LAYOUT from 'lib/common/constants/workspaceLayouts';
import LayoutColumnsIcon from 'lib/common/components/LayoutColumnsIcon';
import SegmentedControls from 'lib/common/components/SegmentedControls';
import Accordion from 'lib/common/components/Accordion';
import PhoneInput from 'lib/common/components/PhoneInput';
import Text from 'lib/common/components/Text';
import Label from 'lib/common/components/Label';
import Button from 'lib/common/components/Button';
import ClickableIcon from 'lib/common/components/ClickableIcon';
import ReactSelect from 'lib/common/components/ReactSelect';

import Checkbox from 'lib/common/components/atoms/Checkbox';

import INCOMING_TASK_PLACEMENTS from 'lib/common/constants/incomingTaskPlacements';
import { usePreferencesContext } from 'lib/common/contexts/PreferencesContext';
import RINGTONE_OPTIONS, { NONE as NO_RINGTONE } from 'lib/common/constants/ringtoneOptions';

import { useAgentContext } from 'lib/common/contexts/AgentContext';
import connectGetter from 'lib/common/utils/connectGetter';
import { useContactContext } from 'lib/common/contexts/ContactContext';
import { LogEvents, logger } from 'lib/common/components/LoggerController';

import IncomingTaskPlacementIcon from './components/IncomingTaskPlacementIcon';

import { AGENT_PREFERENCES, DEFAULT_DEVICE, PHONE_OPTIONS } from './constants';
import { getDeviceOptionsByType } from './helpers';

import './styles/preferences.scss';

function getDefaultDevice(agentConfig, key, getStorageItem) {
  return agentConfig ? getStorageItem(`${agentConfig?.username}:${key}`) || DEFAULT_DEVICE : DEFAULT_DEVICE;
}

const Preferences = () => {
  const [phoneInputTrigger, updatePhoneInputTrigger] = useTrigger();
  const { isSoftphone } = useLayout();
  const {
    state: { onActiveCall, hasConnectingTask }
  } = useContactContext();
  const { agent } = useAgentContext();
  const {
    state: { defaultWorkspaceLayout, ringtone, incomingTaskPlacement, enhancedLoggingEnabled },
    actions: { setDefaultWorkspaceLayout, setRingtone, setIncomingTaskPlacement, setEnhancedLoggingEnabled }
  } = usePreferencesContext();
  const { getStorageItem, setStorageItem } = useLocalStorage();

  const agentConfig = connectGetter(agent, 'getConfiguration');

  const connect = (window as any).getConnect();

  const [isSoftphoneEnabled, setIsSoftphoneEnabled] = useState(connectGetter(agent, 'isSoftphoneEnabled') || false);

  const getDefaultPhoneNumber = () => connectGetter(agent, 'getExtension') || AUSTRALIA_PHONE_EXTENSION;

  const [phoneNumber, setPhoneNumber] = useState(getDefaultPhoneNumber());

  const [deskPhoneButtonsVisible, setDeskPhoneButtonsVisible] = useState(false);

  const [ringtoneAudio] = useState<HTMLAudioElement>(new Audio(ringtone));

  const {
    value: ringtonePlaying,
    toggle: toggleRingtonePlaying,
    setFalse: stopRingtonePlaying
  } = useBooleanState(false);

  const [ringtoneChanged, setRingtoneChanged] = useState(false);

  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);

  const [speakerDevice, setSpeakerDevice] = useState(getDefaultDevice(agentConfig, 'speakerDevice', getStorageItem));
  const [microphoneDevice, setMicrophoneDevice] = useState(
    getDefaultDevice(agentConfig, 'microphoneDevice', getStorageItem)
  );
  const [ringerDevice, setRingerDevice] = useState(getDefaultDevice(agentConfig, 'ringerDevice', getStorageItem));

  useEffect(() => {
    if (!hasConnectingTask) {
      return;
    }

    agent?.setRingerDevice(ringerDevice);
  }, [hasConnectingTask]);

  useEffect(() => {
    if (!onActiveCall) {
      return;
    }

    agent?.setSpeakerDevice(speakerDevice);
    agent?.setMicrophoneDevice(microphoneDevice);
  }, [onActiveCall]);

  useEffect(() => {
    (async () => {
      try {
        const framedMediaDevices: MediaDeviceInfo[] = await connect.core.getFrameMediaDevices(30000);
        setDevices(framedMediaDevices);
      } catch (error) {
        logger.error(LogEvents.MEDIA_DEVICES_LOAD_FAILED, { error });
      }
    })();

    ringtoneAudio?.addEventListener('ended', stopRingtonePlaying);

    return () => {
      ringtoneAudio?.removeEventListener('ended', stopRingtonePlaying);
    };
  }, []);

  const handleSpeakerChange = (option) => {
    setSpeakerDevice(option.value);
    agent?.setSpeakerDevice(option.value);
    setStorageItem(`${agentConfig?.username}:speakerDevice`, option.value);
  };

  const handleMicrophoneChange = (option) => {
    setMicrophoneDevice(option.value);
    agent?.setMicrophoneDevice(option.value);
    setStorageItem(`${agentConfig?.username}:microphoneDevice`, option.value);
  };

  const handleRingerChange = (option) => {
    setRingerDevice(option.value);
    agent?.setRingerDevice(option.value);
    setStorageItem(`${agentConfig?.username}:ringerDevice`, option.value);
  };

  const outputDevicesOptions = getDeviceOptionsByType(devices, 'audiooutput');
  const inputDevicesOptions = getDeviceOptionsByType(devices, 'audioinput');

  const handleSoftPhoneChange = (newValue) => {
    const isSoftPhoneEnabled = newValue?.value === PHONE_OPTIONS[0].value;

    if (!agentConfig) {
      return;
    }

    if (isSoftPhoneEnabled) {
      agent?.setConfiguration({
        ...agentConfig,
        softphoneEnabled: true,
        agentPreferences: AGENT_PREFERENCES
      });
    }

    const savedPhoneNumber = connectGetter(agent, 'getExtension') || AUSTRALIA_PHONE_EXTENSION;
    setPhoneNumber(savedPhoneNumber);

    if (!isSoftPhoneEnabled && PhoneNumber(savedPhoneNumber).isValid()) {
      agent?.setConfiguration({
        ...agentConfig,
        softphoneEnabled: false,
        extension: savedPhoneNumber,
        agentPreferences: AGENT_PREFERENCES
      });
      setDeskPhoneButtonsVisible(false);
    }

    setIsSoftphoneEnabled(isSoftPhoneEnabled);
  };

  const handleDeskPhoneSet = () => {
    if (!agentConfig) {
      return;
    }

    agent?.setConfiguration({
      ...agentConfig,
      softphoneEnabled: false,
      extension: phoneNumber,
      agentPreferences: AGENT_PREFERENCES
    });

    setIsSoftphoneEnabled(false);
    setDeskPhoneButtonsVisible(false);
  };

  const phoneValue = isSoftphoneEnabled ? PHONE_OPTIONS[0] : PHONE_OPTIONS[1];

  return (
    <div className="preferences">
      <Accordion title="Phone" defaultOpen={isSoftphone}>
        <ReactSelect
          aria-label="Phone"
          classNamePrefix="react-select"
          isSearchable={false}
          id="Phone"
          options={PHONE_OPTIONS}
          value={phoneValue}
          onChange={handleSoftPhoneChange}
        />
        {!isSoftphoneEnabled && (
          <div className="preferences__phone-number__container">
            <div className="preferences__phone-number__container__input">
              <PhoneInput
                aria-label="Phone number"
                key={phoneInputTrigger}
                initialValue={phoneNumber}
                onChange={(number) => {
                  setDeskPhoneButtonsVisible(true);
                  setPhoneNumber(number);
                }}
                endInputAdornment={
                  deskPhoneButtonsVisible && (
                    <>
                      <Button
                        ariaLabel="Set default phone number"
                        styleType="SECONDARY"
                        onClick={() => {
                          setPhoneNumber(getDefaultPhoneNumber());
                          updatePhoneInputTrigger();
                          setDeskPhoneButtonsVisible(false);
                        }}
                        icon="faXmark"
                        size="medium"
                      />
                      <Button
                        ariaLabel="Set desk phone"
                        className="ml-5 mr-5"
                        onClick={handleDeskPhoneSet}
                        disabled={!PhoneNumber(phoneNumber).isValid()}
                        icon="faCheck"
                        size="medium"
                      />
                    </>
                  )
                }
              />
            </div>
          </div>
        )}
        {isSoftphoneEnabled && (
          <Label text="Call / Chat Ringtone" id="Ringtone" className="mt-20">
            <>
              <div className="preferences__ringtone">
                <ReactSelect
                  aria-label="Call / Chat Ringtone"
                  classNamePrefix="react-select"
                  isSearchable={false}
                  id="Ringtone"
                  options={RINGTONE_OPTIONS}
                  value={RINGTONE_OPTIONS.find((ringtoneOption) => ringtoneOption.value === ringtone)}
                  onChange={(newValue) => {
                    if (!newValue) {
                      return;
                    }

                    setRingtoneChanged(true);
                    setRingtone((newValue as { value: string }).value);
                    stopRingtonePlaying();

                    ringtoneAudio.src = (newValue as { value: string }).value;
                  }}
                />
                {ringtone !== NO_RINGTONE && (
                  <ClickableIcon
                    aria-label={`${ringtonePlaying ? 'Stop' : 'Play'} ringtone`}
                    icon={ringtonePlaying ? faStop : faPlay}
                    className="preferences__play-icon"
                    onClick={async () => {
                      const isPause = !ringtoneAudio.paused;
                      const action = isPause ? 'pause' : 'play';

                      try {
                        await ringtoneAudio?.[action]();

                        toggleRingtonePlaying();

                        if (!isPause) {
                          return;
                        }

                        ringtoneAudio.currentTime = 0;
                      } catch {
                        /** Silently fail https://goo.gl/LdLk22 */
                      }
                    }}
                  />
                )}
              </div>
              {ringtoneChanged && (
                <>
                  <div className="preferences__warning-text">
                    Please reload the page for the ringtone changes to take effect.
                  </div>
                </>
              )}
            </>
          </Label>
        )}
      </Accordion>
      <Accordion title="Audio Devices" className="mt-30">
        {inputDevicesOptions.some((deviceOption) => deviceOption.label) && (
          <>
            <Label text="Speaker" id="Speaker" className="mb-20">
              <ReactSelect
                aria-label="Speaker"
                classNamePrefix="react-select"
                isSearchable={false}
                id="Speaker"
                options={outputDevicesOptions}
                value={outputDevicesOptions.find((device) => device.value === speakerDevice)}
                onChange={handleSpeakerChange}
              />
            </Label>
            <Label text="Microphone" id="Microphone" className="mb-20">
              <ReactSelect
                aria-label="Microphone"
                classNamePrefix="react-select"
                isSearchable={false}
                id="Microphone"
                options={inputDevicesOptions}
                value={inputDevicesOptions.find((device) => device.value === microphoneDevice)}
                onChange={handleMicrophoneChange}
              />
            </Label>
            <Label text="Ringer" id="Ringer">
              <ReactSelect
                aria-label="Ringer"
                classNamePrefix="react-select"
                isSearchable={false}
                id="Ringer"
                options={outputDevicesOptions}
                value={outputDevicesOptions.find((device) => device.value === ringerDevice)}
                onChange={handleRingerChange}
              />
            </Label>
          </>
        )}
      </Accordion>
      {!isSoftphone && (
        <div className="preferences__workspace-container">
          <div>
            <Text type="heading3" data-testid="widget-item-title" className="mb-10 mt-30">
              Default Workspace Layout
            </Text>
            <SegmentedControls
              ariaLabel="Default workspace layout"
              square
              options={[
                {
                  icon: <LayoutColumnsIcon />,
                  value: WORKSPACE_LAYOUT.THREE_COLUMNS
                },
                {
                  icon: <LayoutColumnsIcon twoColumns />,
                  value: WORKSPACE_LAYOUT.TWO_COLUMNS
                }
              ]}
              initialIndices={defaultWorkspaceLayout === WORKSPACE_LAYOUT.TWO_COLUMNS ? 1 : 0}
              onSelect={setDefaultWorkspaceLayout}
            />
          </div>
          <div>
            <Text type="heading3" data-testid="widget-item-title" className="mb-10 mt-30">
              Incoming Task Placement
            </Text>
            <SegmentedControls
              ariaLabel="Incoming task placement"
              square
              options={[
                {
                  icon: <IncomingTaskPlacementIcon />,
                  value: INCOMING_TASK_PLACEMENTS.BOTTOM
                },
                {
                  icon: <IncomingTaskPlacementIcon top />,
                  value: INCOMING_TASK_PLACEMENTS.TOP
                }
              ]}
              initialIndices={incomingTaskPlacement === INCOMING_TASK_PLACEMENTS.TOP ? 1 : 0}
              onSelect={setIncomingTaskPlacement}
            />
          </div>
        </div>
      )}
      <>
        <Text type="heading3" className="mb-20 mt-30">
          Additional Preferences
        </Text>
        <Checkbox
          checked={enhancedLoggingEnabled}
          label="Enhanced Logging"
          onChange={setEnhancedLoggingEnabled}
          subtitle="Stores additional logs and performance metrics in your log file which can be useful when trying to resolve issues."
        />
      </>
    </div>
  );
};

export default Preferences;
