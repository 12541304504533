import Select, { Props } from 'react-select';

import { useLayout } from 'lib/common/contexts/layout/LayoutContext';
import VARS from 'css/export-vars.module.scss';

import Label from '../Label';
import styles from './react-select.module.scss';

interface TStyles {
  isSoftphone?: boolean;
  small?: boolean;
}

const SMALL_HEIGHT = 35;
const LARGE_HEIGHT = 45;

const getDefaultStyles = ({ isSoftphone, small }: TStyles) => ({
  control: (styles, { isFocused }) => ({
    ...styles,
    borderColor: `${VARS.midGrey} !important`,
    minHeight: `${isSoftphone ? SMALL_HEIGHT : LARGE_HEIGHT}px !important`,

    '&:hover': {
      borderColor: `${isFocused ? VARS.primary : VARS.darkGrey} !important`
    },

    ...(isFocused
      ? {
          borderColor: `${VARS.primary} !important`,
          boxShadow: 'none !important'
        }
      : {})
  }),
  valueContainer: (styles) => ({
    ...styles,
    fontSize: isSoftphone ? VARS.fontSizeSmall : VARS.fontSizeNormal,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    fontSize: isSoftphone ? VARS.fontSizeSmall : VARS.fontSizeNormal,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: VARS.black,
    ...(isFocused && !isSelected ? { backgroundColor: VARS.lightGrey, cursor: 'pointer' } : {})
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  placeholder: small
    ? (provided) => ({
        ...provided,
        position: 'static',
        transform: 'none'
      })
    : undefined,
  singleValue: small
    ? (provided) => ({
        ...provided,
        position: 'static',
        transform: 'none'
      })
    : undefined,
  // Scroll bar stylings
  menuList: (base) => ({
    ...base,

    '::-webkit-scrollbar': {
      width: '8px',
      height: '10px'
    },

    // Track
    '::-webkit-scrollbar-track': {
      background: VARS.background
    },

    // Handle
    '::-webkit-scrollbar-thumb': {
      background: VARS.midGrey,
      borderRadius: '5px'
    },

    // Handle on hover
    '::-webkit-scrollbar-thumb:hover': {
      background: VARS.darkGrey
    }
  })
});

export default function ReactSelect(
  props: {
    small?: boolean;
    label?: string;
  } & Props
) {
  const { isSoftphone } = useLayout();

  const selectElement = (
    <Select
      isSearchable
      styles={getDefaultStyles({ isSoftphone: props.small || isSoftphone, small: props.small })}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary50: VARS.secondary,
          primary75: VARS.secondary,
          primary: VARS.secondary,
          neutral5: VARS.lightGrey
        }
      })}
      menuPortalTarget={document.body}
      menuPosition="fixed"
      aria-label={props.label || props.placeholder?.toString()}
      {...props}
    />
  );

  if (props.label) {
    return (
      <Label id={props.label} text={props.label} className={styles['react-select__label']}>
        {selectElement}
      </Label>
    );
  }

  return selectElement;
}
