import { format } from 'date-fns';
import { Email } from 'postal-mime';

//Currently the style tag is yeeted by lexical but if we can keep it, this will solve the large spacing issue
//may be an issue with both lexical and our serverside lib
const wrapElement = (content?: string) =>
  `<p style='margin: 0;' class='editor-paragraph ltr'>${content || '<br/>'}</p>`;

const buildReplyForwardHtml = ({
  content,
  emailSubject,
  emailAddress,
  allEmailRecipientsArray,
  ccEmailRecipientsArray
}: {
  content: Email;
  emailSubject: string;
  emailAddress: string;
  ccEmailRecipientsArray: string[];
  allEmailRecipientsArray: string[];
}) => {
  const textContent = buildContentText(content?.text);
  return `<p/><p/>
${buildReplyHeaderHtmlContent({
  content,
  emailSubject,
  emailAddress,
  ccEmailRecipientsArray,
  allEmailRecipientsArray
})}
<p/>
${textContent}
`;
};

const buildContentText = (text?: string) => text?.split('\n\n')?.map(wrapElement).join('').replaceAll('\n', '<p/>');

const buildReplyHeaderHtmlContent = ({
  content,
  emailSubject,
  emailAddress,
  ccEmailRecipientsArray,
  allEmailRecipientsArray
}: any) => {
  return `
<p><strong>From:</strong> ${emailAddress}</p>
<p><strong>Sent:</strong> ${format(new Date(content.date), 'EEEE, MMMM d, yyyy hh:mm:ss aa')}</p>
<p><strong>To:</strong> ${allEmailRecipientsArray.join('; ')}</p>
${ccEmailRecipientsArray.length ? `<p><strong>Cc:</strong> ${ccEmailRecipientsArray.join('; ')}</p>` : ''}
<p><strong>Subject:</strong> ${emailSubject}</p>`;
};

export { buildReplyForwardHtml, buildReplyHeaderHtmlContent, buildContentText };
