import { useMemo, useState } from 'react';
import _orderBy from 'lodash.orderby';

import QUEUE_ENDPOINT_TYPES from 'lib/common/constants/queueEndpointTypes';
import { useContactContext } from 'lib/common/contexts/ContactContext';
import { useInstanceContext } from 'lib/common/contexts/InstanceContext';
import getTransferEndpoint from 'lib/common/api/getTransferEndpoint';
import { useAuthContext } from 'lib/core/context/AuthProvider';
import { useConfigContext } from 'lib/core/config';
import quickConnectTypes from 'lib/common/constants/quickConnectTypes';

interface Queue {
  Id: string;
  Arn: string;
  Name: string;
  QueueType: ValueOf<typeof QUEUE_ENDPOINT_TYPES>;
}

export default function useTransferToQueue({ taskId }: { taskId: string }) {
  const [selectedEndpoint, setSelectedEndpoint] = useState<{ value: Queue; label: string } | undefined | null>();

  const {
    actions: { onTransferToQueueOrAgent }
  } = useContactContext();
  const { fetch_ } = useAuthContext();
  const { config } = useConfigContext();
  const { instanceQueues } = useInstanceContext();

  const endpoints = _orderBy(instanceQueues, 'Name', 'asc').map((queue) => ({
    value: queue,
    label: queue.Name
  }));

  const onSave = useMemo(
    () => async () => {
      if (!selectedEndpoint?.value) {
        return false;
      }

      const endpointARN = await getTransferEndpoint({
        fetch_,
        config,
        type: quickConnectTypes.QUEUE,
        identifier: selectedEndpoint.value.Name
      });

      if (!endpointARN) {
        return Promise.reject();
      }

      const endPoint = {
        agentLogin: '',
        endpointARN,
        endpointId: endpointARN,
        name: selectedEndpoint.value.Name,
        phoneNumber: '',
        queue: '',
        type: connect.EndpointType.QUEUE
      };

      return onTransferToQueueOrAgent(endPoint, taskId);
    },
    [selectedEndpoint, config, instanceQueues]
  );

  return {
    onSave,
    endpoints,
    selectedEndpoint,
    setSelectedEndpoint
  };
}
