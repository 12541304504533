import React, { ReactNode, useEffect, useState, useCallback } from 'react';
import { Amplify, Auth } from 'aws-amplify';
import { useHistory, useLocation } from 'react-router-dom';

import EventEmitter from 'lib/common/utils/EventEmitter';
import ErrorPage from 'lib/common/components/Error';
import Loader from 'lib/common/components/Loader';
import getQueryParam from 'lib/common/utils/getQueryParam';
import SignedOutOverlay from 'lib/common/components/atoms/SignedOutOverlay';
import SIGN_OUT_EVENT from 'lib/common/constants/signOutEvent';

import { useConfigContext } from 'lib/core/config';
import { useLocalStorage } from 'lib/common/hooks/useLocalStorage';

import Context from './Context';

type Props = {
  children: ReactNode;
};

const USERNAME_SUFFIX = '@neon.com';

function getChild({ children, user, error, connectToken, userName, signedOut, productName }) {
  if (signedOut) {
    return <SignedOutOverlay hideSignInButton />;
  }

  if (error) {
    return <ErrorPage />;
  }

  if (!connectToken || !userName) {
    return (
      <ErrorPage
        title={`You'll Need To Open The Page From ${productName}`}
        text={`You can only use this page by opening it from ${productName} and won't be able to reload or load it directly. Try clicking the original link.`}
        hidePrimaryAction
      />
    );
  }

  return (
    <>
      {!user ? <Loader /> : null}
      {user && children}
    </>
  );
}

/**
 * This is the auth provider for when the app is in isolated mode. Isolated mode will not mount connect streams
 * and requires query params with token & user ID. A limited sub set of pages are supported in isolated mode.
 */
const IsolatedAuthProvider = ({ children }: Props) => {
  const { config, configLoaded } = useConfigContext();
  const history = useHistory();
  const location = useLocation();
  const [tokens, setTokens] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [mounted, setMounted] = useState(true);
  const [email, setEmail] = useState<string>('');
  const [userData, setUserData] = useState<any>(null);
  const [connectToken] = useState<undefined | string>(getQueryParam({ param: 'token' }));
  const [userName] = useState<undefined | string>(getQueryParam({ param: 'user' }));
  const [signedOut, setSignedOut] = useState<boolean>(false);
  const { getStorageItem } = useLocalStorage();

  useEffect(() => {
    if (!connectToken && !userName) {
      return;
    }

    history.replace(`${location.pathname}?standalone=true`);
  }, []);

  const tenantID = config.TENANT_ID;

  const setUser = async (userId: string, tokens) => {
    const objectKey = `${config.TENANT_ID}__${userId}`;
    const agentUrl = `${config.AGENT_SERVICE_URL}/agent/${objectKey}`;

    try {
      const agentRes = await fetch_(agentUrl, void 0, tokens);
      const data = await agentRes.json();

      const userToStore = { ...data, username: userId };

      setUserData(userToStore);

      EventEmitter.emit('userData', userToStore);

      sessionStorage.setItem('email', userToStore.email || '');
    } catch (e) {
      setError(true);

      console.error('Error fetching user (isolated)', e);
    }
  };

  const checkSession = async () => {
    try {
      if (loaded) {
        return;
      }

      const currentUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();

      if (mounted) {
        setTokens(JSON.parse(JSON.stringify(currentSession)));
      }

      // tenantId__username (non sso) || tenantId__username@company.com (sso)
      const usernameWithTenantId = currentUser.username.includes(USERNAME_SUFFIX)
        ? currentUser.username.split('@')[0]
        : currentUser.username;

      const username = usernameWithTenantId.split('__')[1];

      setUser(username, JSON.parse(JSON.stringify(currentSession)));
    } catch (e) {
      if (e == null) {
        return;
      }
      console.error('Error checking session (isolated)', e);
    }
  };

  const signOutEventListener = useCallback(() => {
    if (!getStorageItem(SIGN_OUT_EVENT)) {
      return;
    }

    setSignedOut(true);
  }, []);

  useEffect(() => {
    window.addEventListener('storage', signOutEventListener);

    if (!connectToken || !userName) {
      return;
    }

    try {
      checkSession();

      if (loaded) {
        return;
      }

      signIn();
    } catch (e) {
      console.error('Error refreshing token (isolated)', e);
    }

    return () => {
      window.removeEventListener('storage', signOutEventListener);
    };
  }, []);

  const fetch_ = async (url, options = {}, tokens_?, throwError = true): Promise<Response> => {
    try {
      const tokensStore = tokens === null ? tokens_ : tokens;
      const headers = new Headers();
      const token = tokensStore?.idToken?.jwtToken;

      if (!token) {
        return Promise.reject('no token');
      }

      headers.append('Authorization', `Bearer ${token}`);
      headers.append('Accept', 'application/json');
      headers.append('Content-Type', 'application/json');

      const result = await fetch(url, { ...options, headers });

      if (!result.ok) {
        const errorBody = await result.json();

        if (throwError) {
          throw new Error(errorBody.message || errorBody);
        }
      }

      return result;
    } catch (e) {
      console.error('Fetch error (isolated)', e);
      throw e;
    }
  };

  const signIn = async () => {
    if (!connectToken || !userName) {
      return;
    }

    try {
      const authUserName = `${tenantID}__${userName}${!userName.includes('@') ? USERNAME_SUFFIX : ''}`; // email address
      const cognitoUser = await Auth.signIn(authUserName);
      const currentSession = await Auth.sendCustomChallengeAnswer(cognitoUser, connectToken);

      setTokens(JSON.parse(JSON.stringify(currentSession.signInUserSession)));
    } catch (e) {
      setError(true);
      console.error('Error signing in: ', e);
    }
  };

  if (configLoaded) {
    Amplify.configure({
      Auth: {
        region: config.COGNITO_USER_POOL_ARN.split(':')[3],
        userPoolId: config.COGNITO_USER_POOL_ARN.split('/')[1],
        userPoolWebClientId: config.COGNITO_CLIENT_ID
      }
    });
  }

  useEffect(() => {
    const getSecureConfig = async () => {
      const userInfoJson = await fetch_(
        `${config.AGENT_SERVICE_URL}/connect/${tenantID}/describe/user/?objectId=${connectToken}`
      );
      const userInfo = await userInfoJson.json();

      // Making userInfo optional as IsolatedAuthProvider user object can be empty
      setEmail(userInfo?.User?.IdentityInfo?.Email);
    };

    const initializeApp = async () => {
      setFetching(true);

      try {
        await getSecureConfig();

        setLoaded(true);
      } catch (e) {
        // Better to console log all errors rather than failing silently
        console.log(e);
        setError(true);
      }
    };

    if (tokens !== null && !fetching) {
      initializeApp();
    }

    return () => {
      setMounted(false);
    };
  }, [tokens]);

  return (
    <Context.Provider
      value={{
        fetch_,
        loaded,
        tokens,
        email,
        userData
      }}
    >
      {getChild({
        children,
        connectToken,
        userName,
        user: userData,
        error,
        signedOut,
        productName: config.BRAND.productName
      })}
    </Context.Provider>
  );
};

export default IsolatedAuthProvider;
