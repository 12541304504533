import { ReactNode } from 'react';
import { useBooleanState } from 'webrix/hooks';
import { useHotkeys } from 'react-hotkeys-hook';

import Button from 'lib/common/components/Button';
import { AGENT_WORKSPACE_HOTKEYS } from 'lib/common/utils/hotkeys/hotkeyMap';

import TransferToQueueModal from '../../../TransferToQueue';
import useTransferModal from './hooks/useTransferModal';

export default function TransferFooterButtons({
  taskId,
  connectionValue,
  disableTransfer,
  showDialpadTransfer,
  showAgentAndQueueTransfer = true,
  children
}: {
  taskId: string;
  connectionValue: string;
  disableTransfer?: boolean;
  showDialpadTransfer?: boolean;
  showAgentAndQueueTransfer?: boolean;
  children?: ReactNode;
}) {
  const {
    value: showTransferToQueue,
    setFalse: closeTransferToQueue,
    setTrue: openTransferToQueue
  } = useBooleanState();

  const { onTransferToAgent, onTransferToDialpad } = useTransferModal();

  useHotkeys(AGENT_WORKSPACE_HOTKEYS.IN_CALL_TRANSFER_CONTACT, onTransferToAgent, [onTransferToAgent]);
  useHotkeys(AGENT_WORKSPACE_HOTKEYS.IN_CALL_TRANSFER_QUEUE, openTransferToQueue, [openTransferToQueue]);
  useHotkeys(
    AGENT_WORKSPACE_HOTKEYS.IN_CALL_TRANSFER_DIAL_PAD,
    (e) => {
      if (!showDialpadTransfer) {
        return;
      }
      // Stop the letter from being typed in the input
      e.preventDefault();
      e.stopPropagation();

      onTransferToDialpad();
    },
    [onTransferToDialpad]
  );

  return (
    <>
      {showAgentAndQueueTransfer && (
        <>
          <Button
            data-testid="directory-transfer-btn"
            icon="faPeopleArrows"
            tooltip={
              !disableTransfer
                ? 'Transfer to a directory contact'
                : 'Cannot transfer as the initial participant has been disconnected'
            }
            styleType="NEUTRAL"
            round
            onClick={onTransferToAgent}
            size="small"
            disabled={disableTransfer}
          />
          <Button
            data-testid="queue-transfer-btn"
            icon="faRectangleHistoryCircleUser"
            tooltip={
              !disableTransfer
                ? 'Transfer to another queue'
                : 'Cannot transfer as the initial participant has been disconnected'
            }
            styleType="NEUTRAL"
            round
            onClick={openTransferToQueue}
            size="small"
            disabled={disableTransfer}
          />
        </>
      )}
      {showDialpadTransfer && (
        <Button
          data-testid="dialpad-transfer-btn"
          // This is a custom icon
          icon="dialpad"
          onClick={onTransferToDialpad}
          tooltip="Dialpad"
          styleType="NEUTRAL"
          round
          size="small"
        />
      )}
      {children}
      {showTransferToQueue && (
        <TransferToQueueModal
          open={showTransferToQueue}
          onClose={closeTransferToQueue}
          connectionValue={connectionValue}
          taskId={taskId}
        />
      )}
    </>
  );
}
