import { MutableRefObject, useEffect, useLayoutEffect, useMemo } from 'react';
import cx from 'classnames';
import { ConstValues, createEnum } from '@cloud-wave/neon-common-lib';
import {
  $getRoot,
  $insertNodes,
  BLUR_COMMAND,
  COMMAND_PRIORITY_HIGH,
  INSERT_PARAGRAPH_COMMAND,
  KEY_ENTER_COMMAND,
  LexicalEditor
} from 'lexical';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { $generateNodesFromDOM } from '@lexical/html';
import { $convertToMarkdownString, TRANSFORMERS } from '@lexical/markdown';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

import { LogEvents, logger } from 'lib/common/components/LoggerController';

import HtmlPlugin from './HtmlPlugin';

import './styles.scss';

function Placeholder() {
  return <div className="editor-placeholder">Say something ...</div>;
}

export const EditorUseCase = createEnum('DirectMessage', 'EmailMessage');
type TEditorUseCase = ConstValues<typeof EditorUseCase>;

export const ReturnEditorStateAs = createEnum('JSONString', 'MarkDown');
type TReturnEditorStateAs = ConstValues<typeof ReturnEditorStateAs>;

function getJSONEditorState(editor: LexicalEditor) {
  return JSON.stringify(editor.getEditorState().toJSON());
}

export default function Editor({
  className,
  onHtmlChange,
  onEditorStateChange,
  content,
  html,
  onSubmit,
  editorUseCase,
  editorFocusRef,
  returnEditorStateAs = ReturnEditorStateAs.JSONString
}: {
  className?: string;
  onHtmlChange: (html: string) => void;
  onEditorStateChange?: (state: string) => void;
  content: string;
  html?: string;
  onSubmit: () => void;
  editorUseCase: TEditorUseCase;
  editorFocusRef?: MutableRefObject<(() => void) | null>;
  returnEditorStateAs?: TReturnEditorStateAs;
}) {
  const [editor] = useLexicalComposerContext();

  const handleEditorStateChange = () => {
    if (returnEditorStateAs === ReturnEditorStateAs.JSONString) {
      onEditorStateChange?.(getJSONEditorState(editor));
    }

    if (returnEditorStateAs === ReturnEditorStateAs.MarkDown) {
      editor.getEditorState().read(() => onEditorStateChange?.($convertToMarkdownString(TRANSFORMERS)));
    }
  };

  const editorStateChangeEventHandler = useMemo(() => handleEditorStateChange, []);

  useEffect(() => {
    editorStateChangeEventHandler();
  }, [getJSONEditorState(editor)]);

  useEffect(() => {
    if (editorFocusRef) {
      editorFocusRef.current = () => editor.focus();
    }
  }, []);

  useEffect(() => {
    editor.registerCommand(
      KEY_ENTER_COMMAND,
      (event: KeyboardEvent) => {
        const { shiftKey, altKey, ctrlKey, metaKey } = event;
        const enterKeyWithoutModifier = [shiftKey, altKey, ctrlKey, metaKey].every((value) => !value);

        const emailUseNewLine =
          editorUseCase === EditorUseCase.EmailMessage &&
          ([shiftKey, altKey, metaKey].includes(true) || enterKeyWithoutModifier);
        const messageUseNewLine =
          editorUseCase === EditorUseCase.DirectMessage && [shiftKey, altKey, ctrlKey].includes(true);

        event?.preventDefault();

        if (emailUseNewLine) {
          editor.dispatchCommand(INSERT_PARAGRAPH_COMMAND, undefined);
          return true;
        }

        if (messageUseNewLine) {
          editor.dispatchCommand(INSERT_PARAGRAPH_COMMAND, undefined);
          return true;
        }

        onSubmit?.();

        return true;
      },
      COMMAND_PRIORITY_HIGH
    );

    editor.registerCommand(
      BLUR_COMMAND,
      () => {
        handleEditorStateChange();

        return false;
      },
      COMMAND_PRIORITY_HIGH
    );

    if (content) {
      try {
        editor.setEditorState(editor.parseEditorState(content));
      } catch {
        // Handling when content is a string and not a stringified JSON
      }
    }

    if (!html) {
      return;
    }

    editor.update(() => {
      const parser = new DOMParser();
      const dom = parser.parseFromString(html, 'text/html');

      const nodes = $generateNodesFromDOM(editor, dom);

      $getRoot().select();

      try {
        $insertNodes(nodes);
      } catch (error) {
        // something went wrong
        // only happens in dev from what I have seen
        // but this will protect us from any weird state issues crashing app
        logger.error(LogEvents.EMAIL.CONTENT_LOAD_FAILED, { error });
      }
      //only on first render seen with the [] in use effect deps
      $getRoot().selectStart();
    });
  }, []);

  useLayoutEffect(() => {}, []);

  return (
    <div className={cx('editor-container', className)} data-testid="text-editor-container">
      <div className="editor-inner">
        <RichTextPlugin
          contentEditable={<ContentEditable className="editor-input" data-testid="text-editor" />}
          placeholder={<Placeholder />}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <HistoryPlugin />
        <AutoFocusPlugin />
        <ListPlugin />
        <LinkPlugin />
        <HtmlPlugin onHtmlChanged={onHtmlChange} />
        <ClearEditorPlugin />
      </div>
    </div>
  );
}
