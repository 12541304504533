import _pick from 'lodash.pick';

import normaliseAWSObjectKeys from '../../utils/customerProfiles/normaliseAWSObjectKeys';
import TProfile from '../../types/CustomerProfile';

type TSearchOptions = {
  config: any;
  fetch_: (url, options) => Promise<any>;
  profileId?: string;
  profile: TProfile;
};

type TProfileId = string;

const UPDATABLE_FIELDS = ['AccountNumber', 'EmailAddress', 'FirstName', 'LastName', 'PartyType', 'PhoneNumber'];

function stripEmptyValues(payload) {
  return Object.entries(payload).reduce((newPayload, [key, value]) => {
    if (typeof value !== 'string' && !value) {
      return newPayload;
    }

    return { ...newPayload, [key]: value };
  }, {});
}

export default async function createUpdateCustomerProfile({
  config,
  fetch_,
  profileId,
  profile
}: TSearchOptions): Promise<TProfileId> {
  const { Address, ...profileFields } = normaliseAWSObjectKeys(profile, { reverse: true });

  const response = await fetch_(`${config['AGENT_SERVICE_URL']}/connect/${config.TENANT_ID}/contacts`, {
    method: profileId ? 'PUT' : 'POST',
    body: JSON.stringify({
      ..._pick(stripEmptyValues(profileFields), UPDATABLE_FIELDS),
      Address: Address ? { Address1: Address } : void 0,
      ...(profileId ? { ProfileId: profileId } : {})
    })
  });

  return response.json();
}
